<template>
  <div class="user-purchase-section">
    <headerCustom></headerCustom>
    <div class="profile-section main-content">
      <div class="heading-sec">
        <div class="row justify-content-between">
          <div class="col-md-4 column">
            <div class="heading-profile">
              <h3>Deals &amp; Offers</h3>
            </div>
          </div>
          <div class="col-md-4">
            <b-button :to="'/viewUserDetail/' + id">Back</b-button>
          </div>
        </div>
      </div>
      <!-- title section end -->
      <div class="panel-content">
        <div class="row">
          <div class="col-md-12">
            <div class="widget">
              <div class="table-area">
                <div class="widget-title">
                  <div class="float-right"></div>
                  <div class="d-flex">
                    <div>
                      <h6>
                        List of Deals &amp; Offers
                        <span class="tableTotal">(Total : {{ totalCount }})</span>
                      </h6>
                    </div>
                    <div class=" ml-3 ">
                      <b-button variant="success" @click="generateExcelForAllOffersNDeals(page)"
                        >Download</b-button
                      >
                    </div>
                    <div class="ml-auto">
                      <form class="search-form mr-form">
                        <input
                          type="text "
                          class="form-control"
                          v-model="searchText"
                          @input="viewHotelList(1)"
                          placeholder="Search Here..."
                        />
                      </form>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table text-nowrap">
                    <thead>
                      <tr>
                        <th>Sr.No.</th>
                        <th>Name</th>
                        <th>Package</th>
                        <th>Offer Name</th>
                        <th>Offer Period</th>
                        <th>Offer Amount</th>
                        <th>Verify Status</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <!-- <th>Action</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in offersNDeals" v-bind:key="item._id">
                        <td>{{ index + 1 }}</td>
                        <td>
                          <div>{{ item.hotel.name }}</div>
                        </td>
                        <td>
                          <div>Package</div>
                        </td>
                        <td>
                          <div>
                            {{ item.offer.name }}
                          </div>
                        </td>
                        <td>
                          <div>
                            {{ item.offer.offerPeriodStart | moment("LL") }} -
                            {{ item.offer.offerPeriodEnd | moment("LL") }}
                          </div>
                        </td>
                        <td>
                          <div>{{ item.totalAmount }}</div>
                        </td>
                        <td>
                          <div>{{ item.status }}</div>
                        </td>
                        <td>
                          <div>
                            {{ item.hotel.city }}
                          </div>
                        </td>
                        <td>
                          <div>{{ item.hotel.state }}</div>
                        </td>
                        <td>
                          <div>{{ item.hotel.country }}</div>
                        </td>
                        <!-- <td>
                          <router-link
                            class="btn btn-primary"
                            :to="{ name: 'deals-offers-form', params: { id: item._id } }"
                            append
                          >
                            <b-icon-eye></b-icon-eye>
                          </router-link>
                        </td> -->
                      </tr>
                      <div>
                        <deletemodal
                          :mymodal="blogData"
                          v-on:conformModal="deleteBlogs"
                        ></deletemodal>
                      </div>
                      <!-- end v-repeat -->
                    </tbody>
                  </table>
                </div>
                <div
                  class="table-body-contents text-center m-3 font-size-md font-weight-bold text-muted"
                  v-if="offersNDeals.length === 0"
                >
                  No Data Found
                </div>
                <div class="text-center" v-if="offersNDeals.length >= 1">
                  <b-pagination
                    class="mb-0 float-right"
                    v-model="page"
                    align="center"
                    :total-rows="totalCount"
                    :per-page="formData.limit"
                    @input="viewOffersNDealsList(page)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import img1 from "@/assets/images/logo.png";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";

export default {
  components: {
    headerCustom
  },
  data() {
    return {
      //   id: "",
      id: this.$route.params.id,

      logs: {
        idToDelete: ""
      },
      currentPage: 1,
      searchText: "",
      offersNDeals: [],
      page: 1,
      formData: { limit: 10 },
      totalCount: 0,
      perPage: 10,
      errors: [],
      data: "",
      email: "",
      blogData: {}
    };
  },
  created() {
    this.viewOffersNDealsList(this.page);
  },
  methods: {
    viewOffersNDealsList(pageNo) {
      this.offersNDeals = [];
      this.formData.page = pageNo;
      this.formData.limit = this.perPage;
      this.formData.search = this.searchText;
      service.viewOffersNDealsList(this.formData, data => {
        if (data.status === 200) {
          this.offersNDeals = data.data.result;
          this.totalCount = data.data.TotalCount;
        } else {
        }
      });
    },
    generateExcelForAllOffersNDeals() {
      service.generateExcelForAllOffersNDeals({}, data => {
        service.downloadExcel(data, "OffersNDeals_List");
      });
    },
    deleteBlogById(user) {
      this.blogData = user;
    },
    deleteBlogs(blog) {
      service.deleteBlogs(blog._id, result => {
        if (result.code == 200) {
          this.$toaster.success("Blogs Deleted");
          this.viewOffersNDealsList(1);
        } else {
          this.$toaster.errors("Something went wrong");
        }
      });
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
.user-purchase-section {
  .user-profile-invoice-edit {
    background: $white;
    .title {
      color: $pink;
      font-size: $font-24;
      font-family: $futuraStd-medium;
      padding: 0.5rem 1.4rem;
    }
    .custom-dropdown {
      padding: 3px !important;
      background: none !important;
      margin-top: 15px;
      margin-left: 3rem;
    }
    .invoice-form-section {
      padding: 1.5rem 1.4rem;
      min-height: 100vh;
      .upload-btn-section {
        padding: 1.9rem 0rem;
        .btn-secondary {
          width: 60% !important;
          &:hover {
            background: $purple;
          }
        }
      }
      .invoice-edit,
      .invoice-save {
        .btn-secondary {
          width: 100% !important;
          padding: 0.3rem 6rem;
          &:hover {
            background: $purple;
          }
        }
      }
    }
  }
  .form-control {
    height: calc(1em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
  }
  .custom-select {
    height: calc(1.3em + 0.75rem + 2px);
    box-shadow: none;
  }
  .form-check {
    padding-top: 2.7rem;
  }
}
</style>
